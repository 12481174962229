import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import './util/i18n/i18next';
import * as serviceWorkerRegistration from './sw/serviceWorkerRegistration';
import PwaInstall from 'util/pwaInstall';
import { isFeatureOn } from './whitelabel';
import { Features } from '@zignaly-open/ps2-definitions';
import { disablePwaZoom } from './sw/util';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <App />
      {isFeatureOn(Features.ServiceWorker) && <PwaInstall />}
    </React.Suspense>
  </React.StrictMode>,
);

isFeatureOn(Features.ServiceWorker)
  ? serviceWorkerRegistration.register()
  : serviceWorkerRegistration.unregister();

disablePwaZoom();
